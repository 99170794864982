import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DeviceDetectionService {
  isIOS(): boolean {
    return /iPad|iPhone|iPod|iOS/.test(navigator.userAgent) || this.getPlatform() === 'MacIntel';
  }

  getPlatform() {
    return navigator?.platform;
  }
}
